// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

// Imported for navigation
import {
  createBrowserRouter,
  RouterProvider,
  Outlet
} from "react-router-dom"; 

// Imports from other pages
import Flows from './pages/Flow';
import CustomTimeline from './Components/Timeline';
import Header from './Components/Header';
import ErrorPage from './pages/ErrorPage';
import Navbar from './Components/Navbar';
import { ReactFlowProvider } from 'react-flow-renderer';

// Template for routers
const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <div>
        <Header />
        <div className="flex">
          <Navbar />
          <div className="flex-1 ml-16 p-4">
            <Outlet />
          </div>
        </div>
      </div>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <CustomTimeline />
      },
      {
        path: "flowme",
        element: <Flows />
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ReactFlowProvider>
    <RouterProvider router={router} />
  </ReactFlowProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals.console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
