import React from 'react';
import {
  VerticalTimeline,
  VerticalTimelineElement
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

const experiences = [
  { id: '0', title: 'Start', description: 'Initial Commit', date: 'July 2021', color: 'rgb(189 214 172)', company: '', link: '' },
  { id: '1', title: 'Hackathon', description: '1st place: Most Creative Hack - Award from Meta', date: 'Feb 2022', color: '#d5a6bd', company: 'Reclaim', link: 'https://www.youtube.com/watch?v=b98NnAtEGjA' },
  { id: '2', title: 'Intern', description: 'Engineering & Marketing Team - Engineer Team Lead', date: 'Mar 2022 - Aug 2022', color: '#a4c2f4', company: 'Blackmore Partners Inc', link: 'https://blackmorepartnersinc.com/' },
  { id: '3', title: 'Intern', description: 'Web Development Team', date: 'Aug 2022 - Jan 2023', color: '#a4c2f4', company: 'Invictus Property Advisors', link: 'https://www.invictusnyc.com/' },
  { id: '4', title: 'Co-Founder', description: 'Reduce, Reuse, Recycle ~ The app to find used treasure', date: 'Oct 2022 - Sep 2023', color: '#b4a7d6', company: 'Stooped', link: 'https://github.com/StoopedLLC/stooped-mobile-v1' },
  { id: '5', title: 'Founder', description: 'It’s more than just a tutoring site. We also create next generation Education Technology', date: 'Aug 2023 - Present', color: '#b4a7d6', company: 'Albuddy', link: 'https://www.albuddy.com/' },
  { id: '6', title: 'Intern', description: 'Global Banking & Markets (FICC & Equities)', date: 'Jun 2024 - Present', color: '#a4c2f4', company: 'Goldman Sachs', link: 'https://www.goldmansachs.com/' },
];

const Timeline = () => {
  return (
    <VerticalTimeline>
      {experiences.slice().reverse().map(exp => (
        <VerticalTimelineElement
          key={exp.id}
          date={exp.date}
          iconStyle={{ background: 'transparent', color: 'rgb(75 85 99)' }}
          icon={<div className="icon-placeholder" />}
          contentStyle={{ background: 'rgb(75 85 99)', color: '#fff', padding: '1.5rem', border: `2px solid rgb(75 85 99)`}}
        >
          <h3 className="tag inline-block px-2 py-1 rounded border" style={{ borderColor: exp.color }}>{exp.title}</h3>
          {exp.company && exp.link && (
            <div className="mt-2 flex items-center space-x-2">
              <a href={exp.link} target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-gray-200">
                {exp.company} <FontAwesomeIcon icon={faExternalLinkAlt} />
              </a>
            </div>
          )}
          <p className="mt-4">{exp.description}</p>
        </VerticalTimelineElement>
      ))}
    </VerticalTimeline>
  );
};

export default Timeline;
