// src/Components/Header.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin, faDiscord, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
  return (
    <div className="top-0 left-0 w-full flex-1 ml-16 text-white p-4 shadow-lg z-10">
      <div>
        <div className="flex items-center">
            <img
                src={require('../../images/Marcus.jpeg')} // Replace with the actual image URL
                alt="Profile"
                className="w-16 h-16 rounded-full mr-4"
            />

            <div>
                <h1 className="text-2xl font-bold mr-2">Marcus Chau</h1>
                <p className="text-sm text-gray-400">New York · Dallas</p>
            </div>
        </div>
        <p className="mt-2"></p>
        <div className="flex space-x-4 mt-2">
          <a href="https://github.com/MarcusChau" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faGithub} size="lg" className="hover:text-gray-300" />
          </a>
          <a href="https://www.linkedin.com/in/marcus-chau/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} size="lg" className="hover:text-gray-300" />
          </a>
          <a href="mailto:marcuschau.business@gmail.com">
            <FontAwesomeIcon icon={faEnvelope} size="lg" className="hover:text-gray-300" />
          </a>
          <a href="https://discord.gg/dSjAXv5a" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faDiscord} size="lg" className="hover:text-gray-300" />
          </a>
          <a href="https://www.youtube.com/@marcusrchau" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faYoutube} size="lg" className="hover:text-gray-300" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
