import React from 'react';
import { useRouteError, Link } from 'react-router-dom';
import './ErrorPage.css';
import ScrollToTop from '../../Components/ScrollTop';

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div className="flex flex-col justify-center items-center h-screen bg-gray-900 text-white">
      <ScrollToTop />
      <div className="text-center">
        <h3 className="text-4xl font-bold mb-4">Oops! Something went wrong...</h3>
        <h4 className="text-2xl text-red-500 mb-8">
          <i>{error.statusText || error.message}</i>
        </h4>
        <Link to="/" className="text-xl text-blue-500 hover:text-blue-300 transition duration-300">
          Go Back Home
        </Link>
      </div>
    </div>
  );
}
