import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faProjectDiagram } from '@fortawesome/free-solid-svg-icons';
// import { faGithub } from '@fortawesome/free-brands-svg-icons';

export default function Navbar() {
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  return (
    <div className="fixed top-10 left-0 w-16 flex flex-col items-center bg-gray-900 text-white shadow-lg rounded-full border-2 border-gray-600">
      <div className="flex flex-col items-center space-y-2 py-6">
        <Link to="/" className={`p-4 flex justify-center items-center rounded-full py-6 ${isActive('/') ? 'bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg' : 'hover:bg-gray-700'}`}>
          <FontAwesomeIcon icon={faHome} />
        </Link>
        <Link to="/flowme" className={`p-4 flex justify-center items-center rounded-full py-6 ${isActive('/projects') ? 'bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg' : 'hover:bg-gray-700'}`}>
          <FontAwesomeIcon icon={faProjectDiagram} />
        </Link>
        {/* <Link to="/experience" className={`p-4 flex justify-center items-center rounded-full py-6 ${isActive('/experience') ? 'bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg' : 'hover:bg-gray-700'}`}>
          <FontAwesomeIcon icon={faBriefcase} />
        </Link> */}
        {/* <Link to="/github" className={`p-4 flex justify-center items-center rounded-full py-6 ${isActive('/github') ? 'bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg' : 'hover:bg-gray-700'}`}>
          <FontAwesomeIcon icon={faGithub} />
        </Link> */}
      </div>
    </div>

  );
}
